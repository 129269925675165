@import 'var';

/*
.top-bar 					: 100
.side-bar					: 99
*/
.login-page {
	@include grid(1fr 370px);
	width: 100vw;
	height: 100vh;
	overflow: hidden;

	.col-left {
		position: relative;
		background: #000;
		width: 100%;
		height: 100%;
		overflow: hidden;

		.caption {
			z-index: 2;
			@include center();
			width: 80%;
			color: white;

			h1 {
				text-transform: uppercase;
				letter-spacing: 5px;
				font-size: 14px;
				font-weight: 700;
				margin-bottom: 40px;
				display: grid;
				grid-template-columns: 60px 1fr;
				-moz-column-gap: 20px;
				     column-gap: 20px;
				align-items: center;
			}

			hr {
				border: 0;
				// margin-bottom: 40px;
				border-bottom: 2px solid white;
				width: 100%;
			}

			p {
				font-size: 40px;
				margin-bottom: 40px;
				line-height: 60px;

			}
		}

		img {
			position: absolute;
			@include img-cover;
			opacity: .7;
			z-index: 1;
		}
	}

	.col-right {
		display: grid;
		align-items: center;

		&>div {
			margin-top: -15%;

			.logo {
				width: 75px;
				display: block;
				margin: 0 auto 24px;
			}

			form {
				display: block;
				margin: 0 auto;
				width: 75%;

				.form-title {
					font-size: 16px;
					text-align: center;
					margin-bottom: 40px;
				}

				.forgot-password {
					color: $blue;
					font-weight: 700;
					text-align: center;
					margin-top: 24px;
					display: inline-block;
					font-size: 13px;
				}

				button {
					margin-top: 32px;
				}
			}
		}
	}

}


.errMsg {
	color: $red;
	font-size: 12px;
	font-weight: 400;
	margin-top: 4px;
}

form {

	// column-gap: 40px;
	// @include grid(1fr 1fr 1fr);
	hr {
		grid-column: 1 / -1;
		border: 0;
		border-bottom: 1px solid #eee;
	}

	.field-container {
		margin-bottom: 24px;

		&.buttons {
			text-align: right;

			button {
				margin-left: 8px;
			}
		}

		&.col-full {
			grid-column: 1/-1;
		}

		&.datepicker {
			.MuiFormControl-marginNormal {
				width: 100%;
			}

			input[type="text"] {
				border-radius: 0;
				border: 0;
			}
		}

		@for $i from 1 through 12 {
			&.col-#{$i} {
				input {
					grid-column: span $i;
				}
			}
		}

		label {
			display: block $i;
			font-weight: 600;
			text-transform: capitalize;
			color: #777;
			display: inline-block;
			margin-bottom: 6px;
			font-size: 13px;
		}

		select {
			@include input();
			display: block;
			width: 100%;
		}

		input {

			&[type="text"],
			&[type="email"],
			&[type="password"] {
				@include input();
				width: 100%;
			}

			&[type="file"] {
				@include input();
				border: 0;
				padding: 0;
				padding-top: 2px;

			}
		}

		.errMsg {
			color: $red;
			font-size: 12px;
			font-weight: 400;
			margin-top: 4px;
		}

		textarea {
			@include input();
			width: 100%;
			height: 100px;
		}
	}
	.field-container-edc {

		select {
			@include input();
			display: block;
			width: 100%;
		}
	}

	.fields-group-container {
		margin-bottom: 16px;
		margin-top: 40px;

		.bordered {
			border: 1px solid #eee;
			grid-column: 1/-1;
			padding: 30px 30px 15px;
			display: grid;
			// grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
			-moz-column-gap: 16px;
			column-gap: 16px;
			background: #fcfcfc;
			border-radius: 3px;
			margin-bottom: 16px;
		}

		&.address-information {
			grid-template-columns: repeat(4, 1fr);

			.field-container {
				&:nth-child(2) {
					grid-column: span 2;
				}
			}
		}

		.field-group-title {
			font-size: 14px;
			color: #333;
			font-weight: 600;
			margin-bottom: 24px;
			grid-column: 1 / -1;
			text-transform: capitalize;

			span {
				display: block;
				color: #aaa;
				font-size: 12.3px;
				font-weight: 400;
				margin-top: 4px;
				margin-bottom: 0px;
			}
		}

		&.buttons {
			display: block;
			text-align: right;
			border-top: 1px solid #eee;
			padding-top: 24px;

			button {
				margin-left: 16px;
			}

			.field-group-title {
				display: none;
			}
		}
	}

	&.form-branch {
		&>div {
			display: grid;
			-moz-column-gap: 40px;
			column-gap: 40px;

			&.general-information {
				grid-template-columns: .7fr 1fr .7fr;
			}

			&.address-information {
				grid-template-columns: repeat(4, 1fr);

				.field-container {
					&:nth-child(2) {
						grid-column: span 2;
					}
				}
			}

			&.contact-information {
				grid-template-columns: repeat(3, 1fr);

			}
		}
	}

	&.form-profile {
		&>div {
			display: grid;
			-moz-column-gap: 40px;
			column-gap: 40px;

			&.general-information {
				grid-template-columns: repeat(4, 1fr);

				.field-container {
					&:nth-child(2) {
						grid-column: span 2;
					}
				}
			}

			&.address-information {
				grid-template-columns: repeat(4, 1fr);

				.field-container {
					&:nth-child(2) {
						grid-column: span 2;
					}
				}
			}

			&.contact-information {
				grid-template-columns: repeat(3, 1fr);

			}
		}
	}

	&.form-member {
		display: grid;
		-moz-column-gap: 56px;
		column-gap: 56px;
		align-items: start;

		.fields-group-container {
			-moz-column-gap: 40px;
			column-gap: 40px;
			@include grid(repeat(12, 1fr));

			.field-container {
				grid-column: span 3;

				@for $i from 1 through 12 {
					&.span-#{$i} {
						grid-column: span $i;
					}
				}
			}

			&.buttons {
				display: block;
				margin-top: 24px;
				grid-column: 1 / -1;
			}
		}
	}

	&.form-contact {
		@include grid(1fr .5fr);
		align-items: start;
		-moz-column-gap: 80px;
		column-gap: 80px;

		.top {
			grid-column: 1/ -1;

			.fields-group-container {
				&.contact-type {
					.field-container {
						display: inline-block $i;
						margin-right: 40px;
					}
				}
			}
		}

		.kanan {
			background: #fafafa;
			padding: 24px;
		}

		.kiri {
			.fields-group-container {
				-moz-column-gap: 40px;
				column-gap: 40px;
				@include grid(repeat(12, 1fr));

				.field-container {
					grid-column: span 3;

					@for $i from 1 through 12 {
						&.span-#{$i} {
							grid-column: span $i;
						}
					}
				}

				&.buttons {
					display: block;
					margin-top: 24px;
					grid-column: 1 / -1;
				}
			}
		}

		.fields-group-container {
			&.buttons {
				grid-column: span 2;
				margin-top: 40px;
			}
		}
	}

	&.form-unit,
	&.form-color {
		input[name="code"] {
			text-transform: uppercase;
		}
	}

	&.reject {
		display: grid;
		grid-template-columns: 1fr .5fr;
		-moz-column-gap: 60px;
		column-gap: 60px;

		&>div {
			p {
				grid-column: 1/-1;
			}

			&:first-child {
				align-content: start;
				width: 90%;
				display: grid;
				-moz-column-gap: 24px;
				column-gap: 24px;
				grid-template-columns: 1fr .7fr;

				.span-textarea {
					grid-column: 1/-1;
				}
			}
		}
	}
}

.alert {
	padding: 12px 16px;
	border-radius: 3px;
	margin-bottom: 16px;

	p {
		font-size: $text-size-md;
		color: white;
		line-height: 22px;

		b {
			text-transform: capitalize;
		}
	}

	&.success {
		background: $green;
	}

	&.error {
		background: $red;
	}

	&.warning {
		background: $orange;
	}

	&.info {
		background: $col-primary;
	}
}

.btn {
	transition: all .3s;
	display: inline-block;
	border: 0;
	font-weight: 600;
	font-size: $text-size-md;
	border-radius: 200px;

	&:hover {
		transition: all 1s;
	}
	&.no-label {
		svg {
			margin: 0;
		}
	}
	&.btn-text {
		background: transparent;
		padding: 0;
		font-size: 13px;
		font-weight: 600;
		width: auto;
		justify-self: start;

		&:hover {
			background: transparent !important;
		}

		&.btn-green {
			color: $teal;
			background: transparent;
		}

		&.btn-blue {
			color: $blue;
			background: transparent;
		}
	}

	&.btn-blue {
		background: rgb(179, 199, 238);
		color: white;
		// box-shadow: 0 0 3px #aaa;
	}

	&.btn-lightblue {
		background: rgb(179, 199, 238);
		color: white;
	}

	&.btn-darkblue {
		background: $blue;
		color: white !important;

		&:hover {
			background: darken($blue, 10%);
		}
	}

	&.btn-rect {
		height: 42px;
		padding: 0 24px;
		border-radius: 3px;
	}

	&.btn-subbtn {
		border-radius: 3px;
		height: 42px;
		width: 42px;
		align-self: center;

		svg {
			margin: 0 $i;
		}
	}

	&.btn-sub {
		border-radius: 3px;
		height: 42px;
		width: 42px;
		margin-bottom: 24px;
		align-self: end;

		svg {
			margin: 0 0 0 -6px $i;
		}
	}

	&.btn-orange {
		background: $col-primary;
		color: white;

		&:hover {
			background: darken($col-primary, 3%);
		}
	}

	&.btn-tile {
		background: $teal;
		color: white;

		&:hover {
			background: darken($teal, 10%);
		}
	}

	&.btn-green {
		background: $teal;
		color: white;

		&:hover {
			background: darken($teal, 10%);
		}
	}

	&.btn-red {
		background: $red;
		color: white;

		&:hover {
			background: darken($red, 10%);
		}
	}

	&.btn-grey {
		color: #555;
		background: #f4f4f4;
	}

	&.btn-block {
		display: block;
		width: 100%;
	}

	&.btn-lg {
		height: 52px;
		font-size: $text-size-md;
	}

	&.btn-md {
		font-size: $text-size-sm;
		box-sizing: border-box;
		padding: 0 28px;
		height: 42px;

		&.btn-wide {
			padding: 0 56px;
		}
	}

	&.btn-secondary {
		background: $col-secondary;
		display: inline-block;
		border-radius: 200px;
		color: white;
		border: 0;
		height: 52px;
		padding: 0 24px;
		font-weight: 600;
		font-size: 14px;

		&:hover {
			background: darken($col-secondary, 5%);
		}
	}

	svg {
		margin-right: 8px;
	}
}

.adminarea {
	background: #f8f8f8;
	padding-top: 100px;

	.topbar {
		background: #fff;
		padding: 12px 0;
		box-shadow: 0px 0 16px rgba(0, 0, 0, .1);
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 100;

		.container {
			@include grid(auto 1fr);

			.logo {
				align-items: center;
				@include grid(auto 1fr);
				grid-column-gap: 8px;
				font-weight: 900;
				font-size: 16px;
				letter-spacing: 3px;
				text-transform: uppercase;

				img {
					width: 40px;
				}
			}

			.menus {
				justify-self: end;
				align-self: center;
				grid-auto-flow: column;
				display: grid;
				grid-column-gap: 24px;

				a {
					align-self: center;

					&.menu {
						img {
							width: 18px;
						}
					}

					&:hover {
						color: $col-primary;
					}

					&.avatar {
						@include grid(28px 1fr);
						grid-column-gap: 4px;
						align-items: center;

						&:hover {
							span {
								color: $col-primary;
							}
						}

						span {
							font-size: $text-size-md;
							font-weight: 600;
						}

						.img {
							@include img-circle-container(28px);
							box-shadow: 0 0 0 4px #ddd;
						}

						svg {
							font-size: 26px;
							color: #ddd;
						}
					}
				}
			}
		}
	}

	.sidebar {
		position: fixed;
		left: 0;
		background: white;
		width: 70px;
		z-index: 99;
		height: 100%;
		top: 0px;
		border-right: 1px solid #eee;
		padding-top: 8%;
		box-sizing: border-box;

		a {
			display: block;
			padding: 8px 0;
			text-align: center;
			margin: 12px 0px;

			&.active {
				background: $col-primary;

				span {
					color: white;
				}
			}

			img {
				width: 30px;
				display: inline-block;
			}

			span {
				font-size: 9px;
				color: #999;
				display: block;
				font-weight: 600;
				text-transform: capitalize;
				margin: 0 auto;
			}
		}
	}

	.page {
		min-height: 100vh;
		padding-bottom: 80px;

		width: calc(95vw - 110px);
		box-sizing: border-box;
		margin-left: 110px;

		.page-header {
			margin-bottom: 16px;
			padding: 0 16px;
			display: grid;
			grid-template-columns: 1fr auto;

			.page-title {
				h3 {
					font-size: 22px;
					color: $col-primary;
					text-transform: capitalize;

					span {
						color: #aaa;
						font-size: $text-size-sm;
						font-weight: 400;
						text-transform: lowercase;
					}
				}
			}

			.page-breadcrumb {
				display: grid;
				grid-auto-flow: column;
				grid-column-gap: 12px;
				align-items: center;

				p {
					font-size: $text-size-md;
					color: $col-text-7;

					&.active {
						color: $col-primary;
					}
				}

				svg {
					width: 5px;
					color: $col-text-3;
				}
			}
		}

		.page-content {
			background: white;
			padding-top: 16px;
			border-radius: 10px;
			box-shadow: 0px 3px 15px rgba(0, 0, 0, .1);

			.tab {
				.tab-nav {
					border-bottom: 1px solid #eee;

					a {
						display: inline-block;
						padding: 28px 28px;
						font-size: 13.3px;
						font-weight: 600;
						color: #ccc;
						margin-bottom: -1px;

						&.active {
							color: $col-text-3;
							border-bottom: 1px solid $col-primary;
						}
					}
				}

				.tab-content {
					.tab-content-item {
						padding: 28px;
						display: none;

						&.active {
							display: block;
						}
					}
				}
			}
		}
	}
}

.module {
	padding: 0 8px;
	min-height: 300px;

	.module-header {
		@include grid(1fr auto);
		margin-bottom: 24px;
		border-bottom: 1px solid #f4f4f4;
		padding-bottom: 24px;
		align-items: center;

		&>div {
			margin-top: 16px;

			&:last-child {
				display: grid;
				-moz-column-gap: 8px;
				column-gap: 8px;
				grid-auto-flow: column;
			}
		}

		button {
			padding: 0 16px;
			border-radius: 3px;
			margin-left: 8px;
			text-transform: capitalize;
		}

		h3 {
			color: $col-text-3;
			font-size: $text-size-xl;
			margin-bottom: 4px;
			text-transform: capitalize;
		}

		p {
			font-size: $text-size-md;
			color: $col-text-9
		}
	}


}
.profile {
		@include grid(200px 1fr);
		-moz-column-gap: 80px;
		     column-gap: 80px;
		align-content: start;
		margin: 30px auto 60px;
		.img {
			padding: 10px;
			border-radius: 1px;
			position: relative;
			height: 200px;
			width: 200px;
			box-sizing:border-box;
			p {
				position: absolute;
				z-index: 5;
				font-size: 12px;
				font-weight: 600;
				background: rgba(0,0,0,.2);
				bottom: -30px;
				padding:6px 10px;
				margin-bottom: 0;
				text-align: center;
				color: white;
				cursor: pointer;
				width: calc(100% + 20px);
				margin: 0;
				box-sizing: border-box;

			}
			img {
				position: absolute;
				width: inherit;
				z-index: 4;
				height: inherit;
				outline: #ddd solid 1px;
				padding: 10px;

				-o-object-fit: contain;
					object-fit: contain;
			}
		}
		.desc {
			padding-top: 20px;
			h1 {
				font-size: 24px;
				text-transform: capitalize;
				margin-bottom: 10px;
				color: #333;
			}
			p.address {
				color: #777;
				margin-bottom: 20px;
				font-size: 15px;
			}
			p.other {
				span {
					display: block;
					color: #555;
					margin-top: 5px;
					margin-right: 30px;
					font-size: 13px;
					font-weight: 600;
				}
			}
		}

		/* &>div {
			margin-top:118px;

			&:first-child {
				.logo {
					border: 1px solid #ddd;
					width: 220px;
					height: 200px;
					display: grid;

					div {
						align-self: center;
						justify-self: center;
						text-align: center;

						p {
							text-align: center;
							font-size: 12px;
							color: #aaa;
							border: 1px solid #ddd;
							display: inline-block;
							font-weight: 600;
							cursor: pointer;
							padding: 4px 12px;
							border-radius: 1000px;
							margin-top: 16px;

							&:hover {
								color: #555;
								border: 1px solid #bbb;
							}
						}

						img {
							width: 50%;
							margin-bottom: 8px;
							display: block;
							margin: 0 auto;
						}
					}

				}
			}

			&:last-child {
				@include grid(1fr 1fr);
				-moz-column-gap: 24px;
				column-gap: 24px;
				margin-top: 40px;

				&>div {
					p {
						border-bottom: 1px solid #eee;
						margin-bottom: 16px;
						padding-bottom: 8px;
						font-size: $text-size-md;
						color: $col-text-7;
						@include grid(.7fr 1fr);

						b {
							color: $col-text-5
						}

						a {
							&:hover {
								color: $col-primary
							}
						}
					}
				}
			}
		} */
	}

.label {
	display: inline-block;
	padding: 2px 12px;
	border-radius: 88px;
	text-transform: capitalize;
	font-weight: 600;
	font-size: 12px;

	&.success {
		background: rgb(1, 209, 195);
		color: white;
	}

	&.danger {
		background: rgb(239, 76, 77);
		color: white;
	}

	&.primary {
		background: #6684f2;
		color: white;
	}

	&.success {
		background: #2ecc71;
		color: white;
	}

	&.warning {
		background: rgb(255, 171, 0);
		color: white;
	}

	&.grey {
		background: #ccc;
		color: #555;
	}

	&.invoiced {
		background: #2196f3;
		color: white;
	}

	&.delivered {
		background: #81c784;
		color: white;
	}

	&.received {
		background: #4caf50;
		color: white;
	}

	&.rect {
		border-radius: 3px;
	}

	&.draft {
		border: 1px solid #ddd;
	}
}

.loading-table {
	text-align: center;
	color: $col-text-7;
	margin: 80px auto;
	background: #f4f4f4;
	padding: 15px 50px;
	border-radius: 3px;
	font-size: $text-size-md;
	width: 60%;
	font-weight: 600;
}

.loading-content {
	display: grid;
	place-items: center;
	height: 250px;
	align-content: center;

	svg {
		font-size: 26px;
		margin-bottom: 24px;
		color: #333;
	}

	p {
		font-size: 13px;
		color: #555;
		font-weight: 600;
	}
}

.loading-inline {
	display: grid;
	grid-template-columns: auto 1fr;
	font-size: 15px;
	align-items: center;
	-moz-column-gap: 8px;
	column-gap: 8px;
	margin-bottom: 16px;

	svg {
		color: #333;
	}

	p {
		color: #555;
		font-weight: 500;
	}
}

.detail {

	&.branch,
	&.member {
		display: grid;
		grid-template-columns: 1fr 1fr;
		-moz-column-gap: 40px;
		column-gap: 40px;
	}

	.row {
		display: grid;
		grid-template-columns: minmax(-webkit-max-content, .5fr) 1fr;
		grid-template-columns: minmax(max-content, .5fr) 1fr;
		border-bottom: 1px solid #eee;
		padding-bottom: 8px;
		margin-bottom: 16px;
		font-size: $text-size-md;
		-moz-column-gap: 40px;
		column-gap: 40px;

		.key {
			font-weight: 600;
			color: $col-text-3;
			padding-left: 16px;
			// &:after {
			// 	content: ' : '
			// }
		}

		.val {
			color: $col-text-7;
			line-height: 22px;

			a {
				&:hover {
					color: $col-primary;
				}
			}
		}
	}

	&.product {
		display: grid;
		grid-template-columns: auto auto;
		-moz-column-gap: 80px;
		column-gap: 80px;
		margin-top: 40px;

		.row {
			display: grid;
			border-bottom: 1px dotted #eee;

			// grid-template-columns: 1fr 1fr 1fr;
			.key {
				font-weight: 600;
				color: $col-text-3;
				padding-left: 16px;

			}

			.val {
				color: #999;

				&::before {
					content: ' : ';
					display: inline-block;
					width: 40px;
				}
			}
		}
	}

	&.style-2 {
		margin: 52px 0 0px;
		grid-template-columns: repeat(5, minmax(100px, 200px));
		// grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

		.col {
			display: inline-block;
			margin-right: 80px;
			margin-bottom: 40px;
			max-width: 200px;
			vertical-align: top;

			span {
				&.key {
					margin-bottom: 8px;
					display: block;
					font-size: 11px;
					text-transform: uppercase;
					font-weight: 700;
					color: rgb(33, 73, 94);

				}

				&.val {
					font-size: 13px;
					line-height: 22px;
					display: block;
					color: #777;
					overflow-wrap: break-word;
				}
			}
		}
	}

	&.variant {
		.col {
			&.variant_value {
				// display: block;
				max-width: 100%;

				span.variant-value {
					background: white;
					margin-right: 6px;
					border: 1px solid #ddd;
					color: #555;
					font-size: 11px;
					font-weight: 600;
					padding: 2px 12px;
					margin-bottom: 0;

				}
			}
		}
	}

	&.contact {
		display: grid;
		-moz-column-gap: 50px;
		     column-gap: 50px;
		.col {
			margin-right: 0;
		}
	}
}

.tablenodata {
	margin: 60px 0;
	display: grid;
	place-items: center;
	align-content: center;
	height: 200px;
	text-align: center;

	img {
		width: 60px;
		opacity: .2;
		margin-bottom: 16px;
	}

	p {
		color: $col-text-5;
		font-weight: 600;
		font-size: $text-size-md;
	}

}

.td-email {
	&:hover {
		color: $col-primary
	}
}

.page-not-found {
	position: absolute;
	border: 2px dashed #ddd;
	@include center;
	padding: 60px;

	h1 {
		margin-top: 24px;
		font-weight: 600;
		font-size: $text-size-md;
		text-align: center;
		line-height: 22px;
		color: #777;

		span {
			display: block;
			font-weight: 800;
		}
	}

	img {
		width: 100px;
		margin: 0 auto;
		display: block;
		opacity: .2;
	}
}

.table-usercategory {
	border-spacing: 0px;
	border-collapse: separate;
	margin-bottom: 32px;

	thead {
		tr {
			margin-bottom: 16px;

			th {
				font-size: 13px;
				background: #eee;
				padding: 16px 0;

				&:nth-child(1),
				&:nth-child(2) {
					text-align: left;
					padding-left: 16px;
				}
			}
		}
	}

	tbody {
		tr {
			&:first-child {
				td {
					padding-top: 16px;
				}
			}

			td {
				border-bottom: 1px solid #eee;
				text-align: center;
				font-size: 13px;

				&:nth-child(1) {
					text-align: left;
					color: #555;
					padding-left: 16px;
					font-weight: 600;
				}

				&:nth-child(2) {
					text-align: left;
					padding-left: 16px;
					color: #777;
				}

				.field-container {
					display: inline-block;
					margin-bottom: 0;

					label {
						margin: 0;
						display: inline-block;

						&>span {
							transform: scale(.9);
						}
					}
				}

			}
		}
	}
}

.table {
	width: 100%;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
	overflow: hidden;
	border-spacing: 0px;
	border-collapse: separate;

	&.table-brand-extra-form {
		width: 100%;

		tr {
			td {
				&:nth-child(1) {
					width: 50px;
				}

				&:nth-child(4) {
					color: #555;
					font-size: 16px;
					margin-right: 8px;
					cursor: pointer;
				}

				&.no-data-yet {
					text-align: center;
					padding: 16px 0;
				}
			}
		}
	}

	thead {
		tr {
			th {
				text-align: left;
				font-size: $text-size-md;
				background: $bgTh;
				font-weight: 600;
				border: 1px solid $bgTh;
				padding: 8px 12px;
			}
		}
	}

	tbody {
		tr {
			td {
				padding: 8px 12px;
				font-size: $text-size-md;
				border-bottom: 1px solid #eee;
				color: #555;
				border: 1px solid #eee;
				border-top: 0;
				border-right: 0;

				&:last-child {
					border-right: 1px solid #eee;
				}
			}
		}
	}
}

.extra-form {
	border-top: 1px dashed #eee;
	padding-top: 24px;
	display: inline-block;
}

.form-product-extra {
	display: grid;
	grid-template-columns: 300px 300px;
	-moz-column-gap: 40px;
	column-gap: 40px;
}

.detail-page {
	padding-bottom: 24px;

	table {
		margin: 16px 0;
	}

	.detail-note {
		font-size: $text-size-md;
		color: #777;
	}
}

.table-form {
	border-collapse: separate;
	border-spacing: 0;

	thead {
		tr {
			th {
				padding: 8px 16px;
				background: $bgTh;
				font-size: $text-size-md;
				color: #555;
				text-align: left;
			}
		}
	}

	tbody {
		tr {
			&:nth-child(even) {
				td {
					background: #fafafa;
				}
			}

			td {
				border-bottom: 1px solid #eee;
				padding: 8px 16px;
				font-size: $text-size-md;
				color: $col-text-7
			}
		}
	}
}

.table {
	th {
		background: $bgTh;
		font-size: $text-size-md;
		padding: 8px 0;
		margin-top: 8px;
	}

	.table-detail {
		border-collapse: collapse;
		margin-bottom: 80px;
		margin-top: 16px;

		th {
			background: $bgTh;
			font-size: 13px;
			padding-left: 24px $i;
			padding-right: 24px $i;
			padding-top: 12px;
			padding-bottom: 12px;

			&:first-child {
				width: 50px;
				padding-right: 0 $i;
			}
		}

		td {
			padding-left: 24px $i;

		}
	}

	&.wide {
		td {
			min-width: 130px;
			white-space: nowrap;
		}
	}
	&.wide-2 {
		td {
			min-width: 90px;
			white-space: nowrap;
		}
	}

	&.table-detail {
		td {
			.penerimaan_qty_received {
				@include input();
				height: 32px;
				width: 80px;
			}
		}

		&.style-2 {
			border-radius: 0px;
			thead {
				th {
					background: rgb(33, 73, 94);
					color: white;
					padding: 12px 16px;
					border-color: rgb(33, 73, 94);

					&.width-as-content {
						white-space: nowrap;
					}
				}
			}

			tbody {
				tr {

					td {
						border: 0;
						padding: 14px 16px;
						border-bottom: 1px solid #ddd;

						&:nth-child(even) {
							background: #fafafa;
						}

						&.highlight {
							font-weight: 600;
							color: rgb(33, 73, 94);
						}

						&.v-top {
							vertical-align: top;
						}

						&.width-as-content {
							white-space: nowrap;
							width: 1%;
						}

						&:last-child {
							border-right: 1px solid #ddd;
						}

						&:first-child {
							border-left: 1px solid #ddd;
						}

						p {
							&.total-inline {
								span {
									display: block;
									font-size: 10px;
									font-weight: 600;
									text-transform: uppercase;
									letter-spacing: 1px;
									margin-bottom: 4px;
								}

								font-size: 16px;
							}
						}

						input {
							@include input();
							width: 100%;
						}
					}

					&:hover {
						td {
							border-bottom: 1px solid #ccc;
						}
					}

					&.total {
						td {
							padding: 16px 16px 8px;
							background: #fff;
							font-size: 13px;
							color: rgb(33, 73, 94);
							font-weight: 700;

							&.note {
								vertical-align: top;
								color: #777;
								border-left: 1px solid #ddd $i;
								font-weight: 500;
								border-right: 1px solid #ddd;

								b {
									display: block;
									margin-bottom: 8px;
									color: rgb(33, 73, 94);
								}

								p {
									margin-bottom: 4px;
								}
							}

							&:first-child {
								border-left: 0;
							}

							&:last-child {
								border-right: 1px solid #ddd;
							}
						}
					}
				}
			}
		}
	}

	&.table-edc {
		tbody {
			tr {
				td {
					input {
						@include input();
						width: 100%
					}
				}
			}
		}
	}
}

.container-grey {
	padding: 32px;
	padding-bottom: 16px;
	border: 1px solid #eee;
	background: #fafafa;
	border-radius: 3px;

}

.something2 {
	grid-column: 1/-1;
	border: 1px solid #eee;
	padding: 24px;
	background: #fafafa;
	border-radius: 3px;
	padding-bottom: 8px;
	margin-top: 8px;

	p {
		display: grid;
		margin-bottom: 8px;
		grid-template-columns: 150px auto;
		font-size: 13px;

		span {
			&:nth-child(1) {
				font-weight: 600;
				color: #333;
			}

			&:nth-child(2) {
				color: #777;
			}

		}
	}

	.subform-reject {
		display: grid;
		margin-top: 32px;
		-moz-column-gap: 40px;
		column-gap: 40px;
		grid-template-columns: 200px 200px .7fr 50px;

		.field-container {
			grid-column: span 1 $i;
		}
	}
}

.something3 {
	// margin-left: -50px;
	display: grid;
	margin-left: -35px;
	align-content: start;
	row-gap: 3px;

	button {
		display: block;
		height: 42px;
		width: 50px;
		text-align: center;
		border-radius: 3px;

		svg {
			margin: 0;
		}
	}

	label {
		display: block;
	}
}

.button-form-reject {
	display: block;
	border-top: 1px solid #eee;
	margin-bottom: 24px;
	padding-top: 16px;
	text-align: right;

	button {
		margin-left: 16px;
	}
}

.button-form {
	display: block;
	border-top: 1px solid #eee;
	margin-bottom: 24px;
	padding-top: 16px;
	text-align: right;

	button {
		margin-left: 16px;
	}
}

.split-button-container {
	grid-column: 1/-1;
	margin-bottom: 32px;
	justify-self: start;
}

span.variant-value {
	display: inline-grid;
	background: $orange;
	border-radius: 3px;
	color: white;
	place-items: center;
	padding: 0 4px;
	text-transform: capitalize;
	margin-right: 4px;
}

.field-container {
	&.inputbutton {
		&>div {
			display: grid;
			align-items: end;
			grid-template-columns: 1fr 50px;

			input {
				border-right: 0;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}

			button {
				width: 100%;
				height: 42px;
				color: white;
				border-radius: 0;
				border-top-right-radius: 3px;
				border-bottom-right-radius: 3px;
				border: 1px solid rgb(1, 209, 195) $i;
				background: rgb(1, 209, 195);
			}
		}
	}
}

.status-text {
	text-transform: capitalize;

	&:before {
		content: ' ';
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		vertical-align: middle;
		margin-bottom: 3px;
		margin-right: 4px;

	}

	&.draft {
		color: inherit;

		&:before {
			background: #ddd;
		}
	}

	&.active,
	&.checked,
	&.paid,
	&.confirmed,
	&.done {
		color: inherit;

		&:before {
			background: $teal;
		}
	}

	&.inactive {
		color: inherit;

		&:before {
			background: $red;
		}
	}

	&.open {
		color: inherit;

		&:before {
			background: $blue;
		}
	}
	&.waiting {
		color: inherit;

		&:before {
			background: $orange;
		}
	}
}

.form-search-product {
	border: 1px solid #eee;
	padding: 32px 32px;
	padding-bottom: 16px;
	border-radius: 3px;
	background: #fafafa;
	margin-bottom: 40px;

	p {
		&.auto-text {
			font-size: 14px;
			row-gap: 8px;
			display: grid;
			color: #999;
			grid-template-columns: 200px 20px auto;

			.title {
				font-weight: 600;
				color: #777;
				font-size: 13px;
			}
		}
	}

	.form {
		margin-top: 28px;
		display: grid;
		border-top: 1px dashed #ddd;
		padding-top: 24px;
		-moz-column-gap: 24px;
		column-gap: 24px;
		grid-template-columns: 200px 150px 150px 200px 52px;

		button {
			align-self: center;
			background: #01d1c3;
			color: white;

			svg {
				margin: 0 $i;
				margin-left: -4px $i;
				font-size: 16px;
			}
		}
	}

	.form-delivery {
		margin-top: 28px;
		display: grid;
		border-top: 1px dashed #ddd;
		padding-top: 24px;
		-moz-column-gap: 24px;
		column-gap: 24px;
		column-gap: 24px;
		grid-template-columns: 200px 150px 150px 200px 150px;

		button {
			align-self: center;
			background: #01d1c3;
			color: white;

			svg {
				margin: 0 $i;
				margin-left: -4px $i;
				font-size: 16px;
			}
		}
	}
}

.table-form-2 {
	border-spacing: 0px;
	border-collapse: separate;
	font-size: 13.3px;
	margin-top: 16px;
	border-bottom: 1px solid #eee;
	margin-bottom: 32px;
	width: 100%;

	&.category-stockopname {
		th:last-child {
			text-align: right;
			width: 100px;
		}

		tr {
			td:last-child {
				text-align: right;
				padding-right: 60px;
			}
		}
	}

	th.action,
	td.action {
		text-align: right;
		padding-right: 16px;

		svg {
			&:hover {
				cursor: pointer;
				color: $red;
			}
		}
	}

	thead {
		th {
			background: rgb(33, 73, 94);
			color: white;
			padding: 14px 16px;
			font-weight: 600;
			padding-right: 60px;
			text-align: left;
		}
	}

	&.no-padding {
		thead {
			th {
				padding-right: 16px;
			}
		}
	}

	tbody {
		tr {
			td {
				border-bottom: 1px solid #eee;
				padding: 16px 16px 14px;
				color: #777;

				&.code {
					color: rgb(33, 73, 94);
					font-weight: 700;
				}

				&.td-button {
					background: #eee $i;
					padding: 12px 16px;
					-moz-text-align-last: left;
					text-align-last: left;

					button {
						background: #fff;
						border: 0;
						padding: 12px 24px;
						border-radius: 3px;
						font-weight: 700;
						color: #555;
						font-size: 13px;
						cursor: pointer;
						box-shadow: 0px 3px 3px #ddd;

						&:hover {
							color: #777;
							background: #fefefe;
							box-shadow: 0px 3px 3px #ccc;
						}
					}

				}
			}

			&:nth-child(even) {
				td {
					background: #fafafa;
				}
			}
		}
	}
}

.input-on-table-row {
	border: 1px solid #ddd;
	font-weight: 400;
	font-size: 13px;
	display: block;
	padding: 8px 12px;
	box-sizing: border-box;
	border-radius: 3px;
	color: #555;
	height: 32px;
	width: 80px;
}

.modal,
.modal-stockopname,
.modal-sales {
	padding: 20px 20px;
	padding-bottom: 35px;
	overflow: hidden;

	.button-container {
		text-align: center;

		button {
			display: inline-block;
			color: white;
			margin: 0 8px;
			background: $blue;
			padding: 12px 38px;
			border-radius: 3px;
			font-size: 13px;

			&:last-child {
				color: white;
				// border:1px solid #ddd;
				background: $orange;
			}

			&:disabled {
				cursor: not-allowed;
			}
		}
	}

	p {
		display: grid;
		margin-bottom: 10px;
		align-items: end;
		font-weight: 600;
		font-size: 20px;
		text-align: center;
		margin-bottom: 40px;
		color: #333;

	}


	.modal-title {
		background: #eee;
		font-weight: 700;
		color: #333;
		padding: 18px 40px;
		margin: -24px;
		margin-bottom: 0;
		font-size: 14px;
		text-transform: uppercase;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		display: grid;
		grid-template-columns: 1fr auto;

		p {
			margin-bottom: 0;
			text-align: left;
			font-size: 14px;
			font-weight: 700;
		}

		svg {
			cursor: pointer;
		}
	}

	.detail {
		&.style-2 {
			margin: 24px 0 0;
		}
	}

	.table-product {
		// box-shadow: inset -10px -3px 13px rgba(0, 0, 0, .1);
		max-height: 400px;
		min-height: 250px;
		margin: 20px 0;
		position: relative;


		th {
			top: 0;
			position: -webkit-sticky;
			position: sticky;
			z-index: 5;
		}

		tbody {
			tr {
				td {
					z-index: 4;
				}
			}
		}
	}

	.total {
		font-size: 13px;
		margin-bottom: 0;
		display: block;
		text-align: left;
		font-weight: 600;
		color: #333;
		margin-top: 24px;

		span {
			display: inline-block;
			margin-right: 24px;
		}
	}
}

.table-container {
	width: 100%;
	overflow: auto;
}

.form-layout-1 {
	.fields-group-container {
		-moz-column-gap: 40px;
		column-gap: 40px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		width: 75%;

		&:not(first-child) {
			margin-top: 16px;
		}
	}

	.form-button {
		padding: 32px 0 24px;
		border-top: 1px dashed #eee;

		button {
			margin-right: 16px;
			border-radius: 3px;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 13px $i;
			height: 48px $i;

			&[type="button"] {
				color: #555;
			}

			&.btn-orange {
				color: white;
			}
		}
	}

	.field-container {
		.MuiInputBase-root ,
		.MuiInput-root ,
		.MuiInput-underline ,
		.MuiInputBase-formControl ,
		.MuiInput-formControl ,
		.MuiInputBase-adornedEnd,
		.MuiInputBase-root,
		.MuiInput-underline {

			border: 0 !important;

			&:before,
			&:after {
				border: 0 !important;
			}
		}
		& > button {
			margin-top: 22px;
		}
		span.note {
			font-size: 12px;
			color: #777;
			line-height: 16px;
			display: block;
			margin-top: 6px;
		}

		&.textarea {
			grid-column: span 2;
		}

		textarea {
			height: 72px;
		}

		&.span-2 {
			grid-column: 1/3;
		}

		&.span-3 {
			grid-column: 1/4;
		}

		.input-number {
			width: 100%;
		}
	}
}

.sales-header {
	// background: #f4f4f4;
	// padding: 16px 16px;
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 3px;

	.sales-item {
		border: 1px solid #eee;
		min-width: 130px;
		display: inline-block;
		border-radius: 5px;
		padding: 20px;
		margin-right: 10px;
		background: #fafafa;

		p {
			&:nth-child(1) {
				font-size: 10px;
				color: #aaa;
				font-weight: 700;
				margin-bottom: 4px;
			}

			&:nth-child(2) {
				font-weight: 700;
				font-size: 18px;
				color: #333;
			}
		}
	}
}

.td-address {
	width: 250px;
	white-space: break-spaces;
}

.td-variant {

	// width: 400px;
	// white-space: normal;
	span {
		display: inline-block;
		margin-right: 4px;
		margin-bottom: 4px;
		border: 1px solid #ddd;
		border-radius: 3px;
		font-size: 11px;
		background: #fff;
		font-weight: 600;
		padding: 5px 10px;
	}
}

.page-detail {
	.detail {
		margin: 52px 0 0px;
		grid-template-columns: repeat(4, minmax(201px, 300px));
		display: grid;
	}
}
.scroll {
	overflow: auto;
}
.scroll-x {
	width: 100%;
	overflow-x: auto;
	&:hover {
		overflow-x: auto;
	}
}



/* component TableProduct */

.table-product {
	width: 100%;
	margin-bottom: 16px;
	overflow-x: auto;
	// height: auto;
	// max-height: 400px;
	&.style-2 {
		max-height: unset;
		overflow-x: auto;
		// margin-bottom: 40px;
		// padding-bottom: 100px;
		// box-sizing: border-box;
	}
	&.variant {
		width: 70%;
		min-width: auto;
		thead {
			th {
				width: auto !important;
			}
		}
		tbody {
			tr {
				td {
					width: auto;
					min-width: auto;
				}
			}
		}
	}

	table {
		min-width: 100%;
		width: auto;
		border-spacing: 0px;
		border-collapse: separate;
		margin-bottom: 24px;
		border-bottom: 1px solid #ddd;
		border-radius: 3px;
		// overflow: hidden;
		thead {
			th {
				font-size: 12px;
				text-transform: uppercase;
				padding: 12px 16px;
				font-weight: 600;
				background: #21495e;
				border-left: 1px solid lighten(#21495e, 5%);
				color: white;
				text-align: left;
				white-space: nowrap;
				width: 1%;

				&.right {
					text-align: right $i;
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 12px 16px;
					font-size: 13px;
					vertical-align: middle;
					border-left: 1px solid #ddd;
					color: #777;
					box-sizing: border-box;
					min-width: 100px;

					&:last-child {
						border-right: 1px solid #ddd;
					}

					&.td-1 {
						.field-container {
							width: 200px;
						}
					}
					&.width-130 {
						min-width: 130px;
					}
					&.width-120 {
						min-width: 120px;
					}
					&.width-250 {
						min-width: 250px;
					}
					&.width-160 {
						min-width: 160px;
					}
					&.width-200 {
						min-width: 200px;
					}
					&.qtyInput {
						white-space: nowrap;
						// min-width: 300px;
						.field-container {
							display: inline-block;
							margin-right: 8px;
						}
						input {
							width: 60px !important;
						}
					}
					&.width-as-content {
						white-space: nowrap;
					}

					&.highlight {
						background: #fafafa;
						font-weight: 600;
						color: #21495e;
						text-transform: capitalize;
					}

					input {
						width: 100%;
						box-sizing: border-box;
						border: 1px solid #ddd;
						padding: 4px 8px;
						border-radius: 3px;
						height: 44px;
						color: #777;
					}

					.field-container {
						margin-bottom: 0;
					}

					&.product_name {
						input {
							width: 200px;
						}
					}

					&.code,
					&.product_name {
						.field-container {
							width: 200px;
						}
					}

					&.qty {
						&.input {
							width: 80px;
						}

						input {
							width: 50px;
							margin-right: 8px;
						}
					}

					&.btn-action {
						vertical-align: middle;
						width: 1px;
						text-align: right;
						white-space: nowrap;

						svg {
							cursor: pointer;

							&:hover {
								color: #333;
							}
						}

						div {
							display: inline-grid;
							grid-template-columns: auto auto;
						}

						button {
							width: 30px;
							height: 30px;
							margin: 0;
							border-radius: 3px;
							display: inline-grid;
							margin-right: 8px;
							cursor: pointer;
							place-items: center;

							svg {
								margin: 0 $i;
								padding: 0 $i;
							}
						}
					}

					&.uom_id {
						width: 130px;

						.field-container {
							width: 100%;
						}
					}

					&.text-red {
						color: $red;
					}
					&.text-center {
						text-align: center;
						input {
							text-align: center;
						}
					}

					&.text-right,
					&.right {
						text-align: right;
						text-align: right;
					}

					button {
						&.btn-tableproduct {
							width: 30px;
							height: 30px;
							border-radius: 3px;

						}

						svg {
							margin: 0 !important;
						}
					}
				}
				&:last-child {
					td {
						border-bottom: 0;
					}
				}
			}
		}

		tfoot {
			tr {
				&.note-total {
					td {
						border-left: 1px solid #ddd;
						padding: 8px 16px;
						border-top: 1px solid #ddd;
						font-weight: 700;
						font-size: 13px;
						color: #333;
						vertical-align: top;

						&:last-child {

							border-right: 1px solid #ddd;
						}

						&.width-as-content {
							white-space: nowrap;
							width: 1%;
						}

						&>b {
							display: inline-block;
							margin-top: 8px;
							margin-bottom: 8px;
						}

						p {
							text-align: left;
							margin-bottom: 8px;
							font-size: 13px;
							color: #777;
							display: block;

							&.note {
								font-weight: 500;
							}

							&.payment-summary {
								span {
									display: inline-block;
									margin-right: 40px;

									b {
										color: $teal;
									}
								}
							}
						}
					}
				}
			}
		}

	}

	.total-container {
		display: inline-grid;
		grid-template-columns: auto auto auto;
		border: 1px solid #ddd;
		padding: 20px 30px;
		grid-auto-flow: column;
		-moz-column-gap: 40px;
		column-gap: 40px;
		margin-bottom: 24px;

		p {
			border-left: 1px solid #ddd;
			font-weight: 700;
			padding-left: 20px;

			&:nth-child(1) {
				border: 0;
				padding-left: 0;
			}

			span {
				&:nth-child(2) {
					font-size: 15px;
					font-weight: 700;
					color: #333;
					padding-bottom: 8px;
				}

				&:nth-child(1) {
					display: block;
					color: #777;
					font-size: 11px;
					padding-bottom: 4px;
					font-weight: 800;
					text-transform: uppercase;
				}
			}
		}
	}

	.header {
		display: grid;
		grid-template-columns: 1fr 1fr;

		p {
			font-weight: 600;
			color: #333;
			font-size: 13px;
			text-align: left;
			margin-bottom: 24px;
			margin-top: 16px;

			&:last-child {
				text-align: right;
			}
		}
	}
}

.table-product-no-max-height-and-overflow {
	width: 100%;
	margin-bottom: 16px;

	table {
		min-width: 100%;
		width: auto;
		border-spacing: 0px;
		border-collapse: separate;
		margin-bottom: 24px;
		border-bottom: 1px solid #ddd;

		thead {
			th {
				font-size: 13px;
				padding: 12px 8px;
				font-weight: 600;
				background: #21495e;
				border: 1px solid #21495e;
				color: white;
				text-align: left;
				white-space: nowrap;
				width: 1%;

				&.right {
					text-align: right $i;
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 12px 8px;
					font-size: 13px;
					vertical-align: middle;
					border-left: 1px solid #ddd;
					color: #777;
					box-sizing: border-box;

					&:last-child {
						border-right: 1px solid #ddd;
					}

					input {
						width: 100%;
						box-sizing: border-box;
						border: 1px solid #ddd;
						padding: 4px 8px;
						border-radius: 3px;
						height: 44px;
						color: #777;
					}

					.field-container {
						margin-bottom: 0;
					}

					&.product_name {
						input {
							width: 200px;
						}
					}

					&.code,
					&.product_name {
						width: 100px;

						// background: #000;
						.field-container {
							width: 200px;
						}
					}

					&.qty {
						&.input {
							width: 80px;
						}

						input {
							width: 80px;
						}
					}

					&.btn-action {
						vertical-align: middle;
						width: 1px;
						text-align: right;
						white-space: nowrap;

						diV {
							display: inline-grid;
							grid-template-columns: auto auto;
						}

						button {
							width: 30px;
							height: 30px;
							margin: 0;
							border-radius: 3px;
							display: inline-grid;
							margin-right: 8px;
							cursor: pointer;
							place-items: center;

							svg {
								margin: 0 $i;
								padding: 0 $i;
							}
						}
					}

					&.uom_id {
						width: 130px;

						.field-container {
							width: 100%;
						}
					}

					&.right {
						text-align: right;
					}
				}
			}
		}

	}

	.total-container {
		display: inline-grid;
		grid-template-columns: minmax(auto, 1fr) 160px;
		grid-template-rows: auto auto;
		grid-auto-flow: column;
		border: 1px solid #ddd;
		border-left: 0;
		border-bottom: 0;
		margin-bottom: 24px;

		p {
			border-bottom: 1px solid #ddd;
			padding-bottom: 4px;
			padding-top: 8px;
			padding: 8px 8px 4px;
			font-weight: 700;


			&:nth-child(odd) {
				color: #21495e;
				font-size: 12px;
				padding-right: 80px;
				background: #fafafa;
				font-weight: 800;
				text-transform: uppercase;
				border-left: 1px solid #ddd;
			}

			&:nth-child(even) {
				font-size: 13.5px;
				color: #333;
				padding-bottom: 8px;
				padding-top: 12px;

				&:not(:nth-child(1)) {
					border-left: 1px solid #ddd;
				}
			}
		}
	}
}


.label-as-baloon {
	position: relative;
	label {
		position: absolute;
		left: 4px;
		font-weight: 900;
		font-size: 10px;
	}
	input {
		text-align: center;
	}
}

hr {
	border: 0;
	border-bottom: 1px solid #eee;
}


// const
.cur-pointer {
	cursor: pointer;
}
.hidden {
	display: none;
}
.h-auto {
	height: auto !important
}
.mh-auto {
	max-height: unset !important
}
.qtyValue{
	display: flex;
	justify-content: center;
	background: #ccc;
	color: #555;
	margin-bottom: 4px;
	font-weight: 600;
	font-size: 11px;
	width: 60px;
	border-radius: 3px;
	padding: 2px;

}
.mr-5{
	margin-right: 5px;
}
.mr-3{
	margin-right: 3px;
}

.w-60{
	font-size: 12px;
	font-weight: 600;
	text-transform: capitalize;
	.field-container{
		display: inline-block;
		margin-right: 10px;
	}
	input{
		height: 30px !important;
		width: 80px !important;
	}
}
.mw-70{
	min-width: 70px;
}
.mw-50{
	min-width: 50px;
}
.mw-100{
	min-width: 100px;
}

.mr-4 {
	margin-right: 4px;
}


//margin
.ml-10 {
	margin-left: 10px;
}
.mb-4 {
	margin-bottom: 4px;
}
.mb-16 {
	margin-bottom: 16px;
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-24 {
	margin-bottom: 24px;
}
.mb-40 {
	margin-bottom: 40px;
}
.mb-50{
	margin-bottom: 50px;
}
.mb-80 {
	margin-bottom: 80px;
}
.mr-10 {
	margin-right: 10px;
}
.mt-0{
	margin-top: 0px !important;
}
.mt-16 {
	margin-top: 16px !important;
}
.mt-50{
	margin-top: 50px;
}
.mt-30{
	margin-top: 30px;
}

.br-0 {
	border-right: 0 $i;
}
.pb-0 {
	padding-bottom: 0 !important;
}
.grid-span-3 {
	grid-column: span 3;
}
.text-right {
	text-align: right !important;
}
.text-center {
	text-align: center !important;
}
.text-capitalize {
	text-transform: capitalize !important;
}
.text-red {
	color: $red;
}
.input-number {
	@include input();
}

// hack
.MUIDataTableSelectCell-headerCell-146 {
	.MuiCheckbox-root-163 {
		color: white !important
	}
}
.MuiButtonBase-root {
	font-family: 'opensans' !important;
	font-size: 13px !important;
	font-weight: 500;
}
.react-select-pw__input {
	height: 100%;
	input {
		height: 100%;
	}
}




.react-datepicker-wrapper {
	width: 100%;
}

// react-select
.custom-react-select__input {
	input {
		height: auto;
	}
}

// react-select input-chips
.input-chips__control {
	border: 1px solid #ddd $i;

	.input-chips__value-container {
		padding: 0 8px;
	}
}

al .select-option {
	width: 100%;
}


.daterange-container {
	position: relative;
	height: 42px;

	.input {
		height: 100%;
		padding: 0 12px;
		border-radius: 3px;
		border: 1px solid #ddd;
		width: 100%;
		box-sizing: border-box;
		position: absolute;
	}

	.daterange-el {
		box-shadow: 0 0 5px rgba(0, 0, 0, .1);
		position: absolute;
		bottom: calc(100% + 8px);
		left: 0;
	}
}

.button-cover {
	margin-top: 15px;
}

/* .custom-borders th,
td {
	border: 1px solid rgba(224, 224, 224, 1);
} */

.button-style-2 {
	height: 44px;
	padding: 0px 16px;
	border-radius: 3px;
	text-transform: capitalize;
}


.ext-print {
	display: none;
}

div[class^="MuiGrid-root-"] > label{
	width: 180px;
	margin-bottom: 10px !important;
}


.asdasd {
	font-weight: 600;
	margin-bottom: 10px;
	color: #555;
	font-size: 14px;
	display: block;
	margin-top: 40px;
	border-top: 1px dashed #ddd;
	padding-top: 40px;
	span {
		color: #aaa;
		font-size:  13px;
		font-weight: 500;
		margin-top: 5px;
		display: block;
	}
}
.report-summary-box {
	.item {
		display: inline-block;
		border:1px solid #ddd;
		padding:15px 10px;
		display: inline-grid;
		place-items: center;
		width: 130px;
		margin-right:10px;
		margin-top: 20px;
		height: 40px;
		border-radius: 12px;
		&.teal {
			border:1px solid $teal;
			background: $teal;
			p {
				span {
					color: white !important;
				}
			}
		}
		&:hover {
			p {
				span {
					&:last-child {
						color: $teal;
						transition: all .3s;
					}
				}
			}
		}
		p {
			span {
				&:first-child {
					display: block;
					text-transform: uppercase;
					font-weight: 600;
					font-size: 10px;
					margin-bottom: 2px;
					color: #999;
				}
				&:last-child {
					font-weight: 700;
					transition: all .3s;
					color:#555;
					font-size: 14px;
				}
			}
		}
	}
}
.report-summary-pill {
	margin-top: 30px;
	margin-bottom: 30px;
	border-radius: 3px;
	p {
		display: inline-block;
		// padding: 10px 20px;
		background: #eee;
		padding: 5px 20px;
		border-radius: 111px;
		margin-right: 10px;
		&:before {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			margin-right: 5px;
			background: $indigo;
			display: inline-block;
			content: " ";
		}
		span {
			font-weight: 800;
			font-size: 13px;
			color: #333;
			&:first-child {
				color: #555;
				display: inline-block;
				font-size: 12px;
				font-weight: 600;
				margin-right: 5px;
			}
		}
	}
}
.initial-branch {
	// display: inline-block;
	// width: 30px;
	// height: 30px;
	// border-radius: 50%;
	// margin-right: 3px;
	cursor: pointer;
	text-transform: uppercase;
	// display: inline-grid;
	// border: 1px solid #ccc;
	// place-items: center;
}
.table-menu {
	display: grid;
	align-items: end;
	margin-top: 24px;
	grid-template-columns: 1fr auto;
	margin-bottom: 8px;
	button {
		background: transparent;
		font-size: 11px;
		background: #f4f4f4;
		width: 130px;
		border: 1px solid transparent;
		border-bottom:1px solid #ddd;
		padding: 5px 0;
		cursor: pointer;
		text-transform: uppercase;
		color: #777;
		font-weight: 600;
		// border: 0;
		margin-right: 8px;
		border-radius: 5px;
		&:hover {
			color: #333;
			border:1px solid #f4f4f4;
			border-bottom: 1px solid #ddd;
			background: #fafafa;
		}
	}
	.note {
		font-size: 12px;
		color: #777;
		font-style: italic;
		font-weight: 600;
	}
}
.table-sticky {
	position: relative;
	max-height: 360px;
	overflow: auto;
	&.more-height {
			max-height: 555px;
	}
	table {
		overflow: unset;
		margin-top: 0;
		margin-bottom: 0;
	}
	th {
		position: sticky !important;
		top: 0 !important;
		z-index: 1;
	}

	@for $i from 1 through 10 {
		.sticky-variant-row-#{$i} {
			td, th {
				position: sticky !important;
				top: #{(32 * $i) + $i}px !important
			}
			td {
				background: white;
			}
		}
	}

	.is-sticky-row {
		td {
			position: sticky !important;
			top: 0 !important;
		}
	}
}
.table-report {
	border-spacing: 0px;
	border-collapse: separate;
	margin-top: 20px;
	border-radius: 3px;
	overflow: hidden;
	width: 100%;
	margin-bottom: 40px;
	thead {
		th {
			background: rgb(33, 73, 94);
			color: white;
			text-align: left;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 700;
			padding:8px 12px;
			&.sortable {
				& > div {
					display: inline-grid;
					align-items: center;
					justify-content: start;
					grid-template-columns: auto 15px;
				}
				&.desc {
					.arrow-asc {
						border-top-color: rgba(255, 255, 255, .6);
					}
				}
				&.asc {
					.arrow-desc {
						border-bottom-color: rgba(255, 255, 255, .6);
					}
				}
			}
			.sort {
				display: inline-block;
				position: relative;
				display: inline-grid;
				margin-left: 4px;
				width: 15px;
				height: 15px;
				& > div {
					width: 0;
					height: 0;
					position: absolute;
					border:5px solid transparent;
				}
				.arrow-asc {
					border-top: 5px solid rgba(255, 255, 255, .3);
					bottom: -4px;
				}
				.arrow-desc {
					top: -3px;
					border-bottom: 5px solid rgba(255, 255, 255, .3);
				}
			}
			&.th-text-right {
				text-align: right;
			}
			&.th-text-center {
				text-align: center;
			}
			&.th-darker {
				background: darken(rgb(33, 73, 94), 2%);
			}
		}
	}
	tbody {
		tr{
			&:hover {
				td {
					border-bottom: 1px solid #eee;
					// transition: all .3s;
					background: #fafafa;
				}
			}
			&.highlight {
				&:hover {
					td {
						background: #f2f2f2 !important;
						border-color: #ddd !important;
					}
				}
				td {
					background: #f4f4f4;
					text-transform: capitalize;
					font-weight: 700;
					font-size: 12px;
					color: rgb(33, 73, 94);
					padding: 8px 12px;
					border-color: #e3e3e3 !important;
					&.td-darker {
						background: #ddd;
					}
				}
				&.tr-darker {
					td {
						background: #ececec;
					}
					&:hover {
						td {
							background: #e8e8e8 !important;
						}
					}
				}
			}
			td{
				font-size: 12px;
				color: #777;
				padding: 10px 12px 8px;
				// transition: all .3s;
				border:0;
				border-bottom: 1px solid #eee;
				&.td-200 {
					min-width: 200px;
				}
				&.td-70 {
					min-width: 70px;
				}
				&.td-index {
					white-space: nowrap;
					width: 1%;
					min-width: 40px;
				}
				&.td-highlight {
					font-weight: 700;
					color: #333;
				}
				&.td-text-right {
					text-align: right;
				}
				&.td-text-center {
					text-align: center;
				}
				&.td-currency {
					white-space: nowrap;
					width: 1%;
					// min-width: 150px;
					// max-width: 150px;
				}
				&.td-width-as-content {
					width: 0%;
					white-space: nowrap;
				}
				&.td-darker {
					background: #f4f4f4;
				}

				&.tdsort.sortable {
					& > div {
						display: inline-grid;
						align-items: center;
						justify-content: start;
						grid-template-columns: auto 15px;
					}
					&.desc {
						.arrow-asc {
							border-top-color: #bbb;
						}
					}
					&.asc {
						.arrow-desc {
							border-bottom-color: #bbb;
						}
					}
				}
				.sort {
					display: inline-block;
					position: relative;
					display: inline-grid;
					margin-left: 4px;
					width: 15px;
					height: 15px;
					& > div {
						width: 0;
						height: 0;
						position: absolute;
						border:5px solid transparent;
					}
					.arrow-asc {
						border-top: 5px solid #ccc;
						bottom: -4px;
					}
					.arrow-desc {
						top: -3px;
						border-bottom: 5px solid #ccc;
					}
				}
			}
		}
	}
	&.pos-product-general {
		td {
			&.sortable {
				& > div {
					display: inline-grid;
					align-items: center;
					justify-content: start;
					grid-template-columns: auto 15px;
				}
				&.desc {
					.arrow-asc {
						border-top-color: #bbb;
					}
				}
				&.asc {
					.arrow-desc {
						border-bottom-color: #bbb;
					}
				}
			}
			.sort {
				display: inline-block;
				position: relative;
				display: inline-grid;
				margin-left: 4px;
				width: 15px;
				height: 15px;
				& > div {
					width: 0;
					height: 0;
					position: absolute;
					border:5px solid transparent;
				}
				.arrow-asc {
					border-top: 5px solid #ddd;
					bottom: -4px;
				}
				.arrow-desc {
					top: -3px;
					border-bottom: 5px solid #ddd;
				}
			}
			&.aa {
				background: #eee;
				min-width: 40px;
				text-align: center;
				border-bottom: 1px solid #ddd;
			}
			&.bb {
				min-width:40px;
				border-bottom: 1px solid #ddd;
				background: #f4f4f4;
				text-align: center;
			}
		}
	}
	&.inventory-stock,
	&.inventory-stock-1-1,
	&.inventory-by-cust,
	&.inventory-mutation,
	&.inventory-stockopname {
		.trigger-show-detail {
			&:hover{
				color: $orange;
				cursor: pointer;
			}
		}
		.cell-main {
			td {
			padding-top: 15px;
			padding-bottom: 15px;
			}
		}
		th {
			border-bottom: 1px solid rgba(255, 255, 255, .2);
			border-right: 1px solid rgba(255, 255, 255, .2);
		}
		tr.detail-heading {
			td {
				background: #eee;
				font-weight: 600;
				text-transform: capitalize;
				border-bottom: 1px solid #ddd;
			}
		}
		tr.detail-body {
			td {
				background: #fafafa;
				border-color: #ddd;
			}
			&:last-child {
				td {
				}
			}
		}
		tr {
			&.hide {
				display: none;
			}
			td {
				.info-icon {
					font-size: 13px;
					line-height: 0px;
				}
				border-left: 1px solid #ddd;
				&:last-child {
					border-right: 1px solid #ddd;
				}
			}
		}
	}
}
.cell-tc {
	text-align: center !important;
}
.cell-no-highlight {
	font-weight: normal !important;
}
.cell-capitalize {
	text-transform: capitalize;
}
.cell-tr {
	text-align: right !important;
}
.cell-highlight{
	font-weight: 700;
	color: #333;
}
.cell-currency {
	white-space: nowrap;
	width: 1%;
	min-width: 150px;
}
.cell-width-as-content {
	white-space: nowrap;
	width: 1%;
}
.cell-vat {
	vertical-align: top;
}
.cell-min-width-200 {
	min-width: 200px;
}
.cell-min-width-30 {
	min-width: 30px;
}
.cell-min-width-150 {
	min-width: 150px;
}
.cell-min-width-100 {
	min-width: 100px;
}
.cell-bg-d {
	background: #ddd !important;
	border-bottom-color: #ccc !important;
}
.report-pos {
	h5 {
		color: rgb(33, 73, 94);
		text-transform: capitalize;
		font-size: 13px;
		svg {
			margin-bottom: -5px;
		}
	}
}
.kop-surat-component{
	display: none;
}
.print-notif{
	// width: 50%;
	.alert{

		p{
			display: inline !important;
			margin-bottom: 10px !important;
			align-items: end !important;
			font-weight: 300 !important;
			font-size: 13px !important;
			color: #fff !important;
		}
	}

}
.image-on-detail-pos {
	label{
		    margin-bottom: 10px;
		    display: block;
		    font-size: 11px;
		    text-transform: uppercase;
		    font-weight: 700;
		    color: #21495e;
	}
	img{
		cursor: pointer;
	}

}
.proof-image-box{
	    padding: 10px;
	    border-radius: 1px;
	    height: 300px;
	    width: 250px;
		box-sizing: border-box;
		.change-proof-image{
			cursor: pointer;
			background: rgba(0, 0, 0, 0.2);
			margin-top: -45px;
			text-align: center;
			z-index: 9999;
			position: relative;
			width: 250px;
			height: 30px;
			color: white;
			padding-top: 5px;
			padding-bottom: 5px;
		}
}
.fileUploader{
	.fileContainer {
		background: #fff;
		box-shadow: none !important;
		position: relative;
		border-radius: 10px;
		padding: 0 0 !important;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 10px auto;
		transition: all 0.3s ease-in;

	}
}

@page {
	margin-right: 2%;
	margin-left: 2%;
}
@media print {
	// set body supaya menampilkan konten print sesuai dengan style nya
	html, body {
		-webkit-print-color-adjust: exact !important;
		height:100vh;
		margin: 0 !important;
		padding: 0 !important;
		overflow: hidden;
	}
	.print-mt-80 {
		&:first-child {
			margin-top: 80px;
		}
	}
	.print-mt-120 {
		margin-top: 120px;
	}
	.print-mt-220 {
		&:first-child {
			margin-top: 220px;
		}
	}
	.detail-page {
		padding-bottom: 0px !important;
	}
	.key {
		font-size: 15px !important;
	}
	.val {
		font-size: 15px !important;
	}
	.table-container{
		  overflow:visible;
		  table{
			  width: 100% !important;
			  tr{
				th{
					width: auto !important;
					min-width: 0 !important;
					padding: 3px 1px !important;
					text-align: center !important;

				  }
				td{
					width: auto !important;
					min-width: 0 !important;
					padding: 3px 1px !important;
					text-align: center !important;
				}
				.note{
					width: auto !important;
					min-width: 0 !important;
					padding: 3px 1px !important;
					text-align: start !important;
				}
			  }

		  }
	}
	.table-detail {
			thead, tbody, td,tr{
				border: 1px solid #ddd !important;
			}
			tr{
				.note{
					border: 1px solid #ddd !important;
				}
			}
	}
	.module{
		padding-left: 20px !important;
		padding-right: 20px !important;
		.module-header{
			display: none !important;

		}
	}
	.asdasd {
		border-top: none !important;
	}
	.kop-surat-component{
		margin-top: -40px;
		padding-left: 20px;
		padding-right: 20px;
		display: block;
		margin-bottom: -30px;
		.kop-surat{
			margin-top: 30px;
			display: flex;
			// flex-wrap: nowrap;
			width: 100%;
			// justify-content: center;
			.kop-surat-image{
				// width: 10%;
				// background-color: purple;
				// position: relative;
				// margin-left: -330px;
				display: flex;
				justify-content: flex-start;
				// align-items: center;
				.logo{
					img{
						// background-color: green;
						border: 1px  #ddd;
						width: 150px;
						height: 105px;
						display: grid;
					}
				}
			}

			.kop-surat-information{
				div{
					font-family: 'Trebuchet MS' !important;
				}
				// background-color: grey;
				margin-left: 15px;
				margin-top: 0px;
				width: 100%;
				position: relative;
				text-align: left;
				display: flex;
				justify-content: flex-start;
				.company-name{
					letter-spacing: 1px;
					// background-color: yellow;
					margin-top: 5px;
					font-size: 20px;
					font-weight: 700;
				}
				.company-address{
					// background-color: green;
					font-size: 14px;
					letter-spacing: 1px;
					font-weight: 300;
					padding-top: 1px;
				}
				.company-phone{
					// background-color: blue;
					font-size: 14px;
					letter-spacing: 1px;
					font-weight: 300;
					padding-top: 1px;
				}
				.company-email{
					// background-color: pink;
					font-size: 14px;
					letter-spacing: 1px;
					font-weight: 300;
					padding-top: 1px;
				}
				.company-website{
					font-size: 14px;
					letter-spacing: 1px;
					font-weight: 300;
					padding-top: 1px;
				}
			}
		}
		.line-header{
			height: 10px;
			width: 100%;
			border-bottom: 1px solid #ddd;
			margin-bottom: 10px;
		}
		.line-header-non-title{
			height: 10px;
			width: 100%;
			border-bottom: 1px solid #ddd;
			margin-bottom: -60px;
		}
		.title{
			font-size: 20px;
			font-weight: 700;
			color: #21495E !important;
		}
		.nomor{
			font-size: 15px;
			font-weight: 300;
			color: #777 !important;
		}
	}
	table {
		overflow: hidden;
		width: 100% !important;
	}
	.react-easy-print-print {
		width: 100% !important;
		position: absolute;
		display: block;
		left: 0;
		top: 0;
		font-size: 11px;
		overflow: visible;

	}
	.ext-print {
		display: block;
	}
	div[class^="MUIDataTableFilterList-root-"]{
		display: none;
	}
	// remove styling label in table
	.label {
		font-size:  12px;

		&.success {
			background: transparent !important;
			color: #555 !important;
		}
		&.danger {
			background:transparent !important;
			color: #555 !important;
		}
		&.primary {
			background:transparent !important;
			color: #555 !important;
		}
		&.warning {
			background:transparent !important;
			color: #555 !important;
		}
		&.grey {
			background:transparent !important;
			color: #555 !important;
		}
		&.invoiced {
			background:transparent !important;
			color: #555 !important;
		}
		&.delivered {
			background:transparent !important;
			color: #555 !important;
		}
		&.received {
			background:transparent !important;
			color: #555 !important;
		}
	}
	.status, .type {
		display: none !important;
	}
	.col {
		margin-right: 40px !important;
		margin-bottom: 20px !important;
	}
	.spacer-20{
		height: 20px;
	}
	.spacer-30{
		height: 30px;
	}
	.spacer-60{
		margin-top: 70px;
	}
	.qtyValue {
		background:transparent !important;
		color: #555 !important;
	}
	.total-inline{
		font-size: 12px !important;
	}
	.sales-header{
		display: none;
	}
	.category, .promotion, .brand, .brand-comission, .mutation, .stock-product {
		td, th {
			min-width: 10px !important;
		}
	}
	.scroll-x {
		width: auto;
		overflow-x: unset;
	}
	.print-invoice {
		margin-top: 15px !important;
	}
	.print-report {
		// &.zoom-90 {
		// 	zoom: 90% !important;
		// }
		// &.zoom-85 {
		// 	zoom: 85% !important;
		// }
		// &.zoom-80 {
		// 	zoom: 80% !important;
		// }
		// &.zoom-75 {
		// 	zoom: 75% !important;
		// }
		// &.zoom-70 {
		// 	zoom: 70% !important;
		// }
		// &.zoom-60 {
		// 	zoom: 58% !important;
		// }
		// &.zoom-50 {
		// 	zoom: 50% !important;
		// }
		.scroll-x {
			overflow-x: hidden;
		}
	}
	.report-summary-box{
		.item{
			p {
				span {
					&:first-child {
						font-size: 8px;
					}
					&:last-child {
						font-size: 9px;
					}
				}
			}
			margin-right: 8px;
			padding: 8px 16px;
			width: auto;
		}
	}
	.report-summary-pill .mt-16 {
		margin-bottom: 16px !important;
		margin-top: 8px !important;
	}
	.table-report{
		thead {
			th {
				font-size: 8px;
				padding: 6px 4px;
				.sort {
					display: none;
				}
				&.sortable {
					& > div {
						grid-template-columns: auto;
					}
				}
			}
		}
		tbody{
			tr{
				&.highlight{
					td {
						font-size: 8px;
						padding: 6px 4px;
					}
				}
				td {
					font-size: 8px !important;
					color: #333;
					padding: 6px 4px;
					&.sortable {
						.sort {
							display: none;
						}
					}
				}
			}
		}
		&.pos-product-general {
			td {
				&.sortable {
					& > div {
						grid-template-columns: auto;
					}
				}
				&.aa {
					min-width: 20px;
				}
				&.bb {
					min-width:20px;
				}
			}
		}
	}
	.mw-100 {
		min-width: 70px;
	}
}


@media only screen and (max-width: 1600px) {
	.pos-product-general,
	.inventory-stock-1-1 {
		tr {
			td{
				background: white;
			}
		}
		.sticky-variant-row-1 {
			td, th {
				position: sticky !important;
				top: 33px !important
			}
		}
		.sticky-variant-row-2 {
			td, th {
				position: sticky !important;
				top: 81px !important
			}
		}
		.sticky-variant-row-3 {
			td, th {
				position: sticky !important;
				top: 130px !important
			}
		}
		.sticky-variant-row-4 {
			td, th {
				position: sticky !important;
				top: 180px !important
			}
		}
		.sticky-variant-row-5 {
			td, th {
				position: sticky !important;
				top: 231px !important
			}
		}
	}
}

@media only screen and (min-width: 1601px) {
	.pos-product-general,
	.inventory-stock-1-1 {
		tr {
			td{
				background: white;
			}
		}
		.sticky-variant-row-1 {
			td, th {
				position: sticky !important;
				top: 34px !important
			}
		}
		.sticky-variant-row-2 {
			td, th {
				position: sticky !important;
				top: 85px !important
			}
		}
		.sticky-variant-row-3 {
			td, th {
				position: sticky !important;
				top: 136px !important
			}
		}
		.sticky-variant-row-4 {
			td, th {
				position: sticky !important;
				top: 187px !important
			}
		}
		.sticky-variant-row-5 {
			td, th {
				position: sticky !important;
				top: 238px !important
			}
		}
	}

}


.row-red {
	td {
		color: red !important;
	}
}

.swal2-container {
	z-index: 10000 !important;
}

.bg-red {
	background: red !important;
}
