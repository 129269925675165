$blue: #3367d6;
$indigo: #6574cd;
$purple: #922c88;
$pink: #e4c6d0;
$red: #e3342f;
$orange: #f78300;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$bgTh: #e4efff;

$col-primary: $orange;
$col-secondary: #1c768f;
$col-text-3: #333;
$col-text-5: #555;
$col-text-7: #777;
$col-text-9: #999;

$i: !important;
$os: opensans;

$adm-sidebar-width: 250px;
$adm-topbar-height: 65px;

$text-size-md: 13px;
$text-size-sm: 12px;
$text-size-xl: 17px;
$text-size-lg: 15px;

@mixin input {
    border:1px solid #ddd;
    height: 44px;
    font-weight: 400;
    font-size: $text-size-md;
    color: $col-text-5;
    display: block;
    padding: 8px 12px;
    box-sizing: border-box;
    border-radius: 3px;
    color: $col-text-5;
}

@mixin img-cover {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}

@mixin img-circle-border($size) {
    box-shadow: 0px 0 0 $size white,
        0px 0 0 $size + 1 #eee;
}

@mixin img-circle-container($size) {
    @include square($size);
    overflow: hidden;
    border-radius: 50%;

    img {
        @include img-cover;
    }
}

@mixin square($size) {
    width: $size;
    height: $size;
}

@mixin grid($cols) {
    display: grid;
    grid-template-columns: $cols;
}

@mixin center($direction: '') {
    position: absolute;

    @if $direction==v {
        top: 50%;
        transform: translateY(-50%);
    }

    @else if $direction==h {
        left: 50%;
        transform: translateX(-50%);
    }

    @else {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@font-face {
    font-family: 'opensans';
    src: url('../fonts/opensans/OpenSans-Light.ttf') format(truetype);
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'opensans';
    src: url('../fonts/opensans/OpenSans-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'opensans';
    src: url('../fonts/opensans/OpenSans-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'opensans';
    src: url('../fonts/opensans/OpenSans-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'opensans';
    src: url('../fonts/opensans/OpenSans-SemiBold.ttf');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'opensans';
    src: url('../fonts/opensans/OpenSans-SemiboldItalic.ttf');
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: 'opensans';
    src: url('../fonts/opensans/OpenSans-Bold.ttf');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'opensans';
    src: url('../fonts/opensans/OpenSans-BoldItalic.ttf');
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: 'opensans';
    src: url('../fonts/opensans/OpenSans-ExtraBold.ttf');
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: 'opensans';
    src: url('../fonts/opensans/OpenSans-ExtraBoldItalic.ttf');
    font-style: italic;
    font-weight: 800;
}
 

 

 

* {
    outline: none;
    padding: 0;
    font-family: 'opensans';
    outline: 0;
    color: inherit;
    font-size: inherit;
    margin: 0;
    text-decoration: none;
}

html {
      scroll-behavior: smooth;

}

.container {
    width: 90%;
    margin: 0 auto;
}

h6 {
    font-size: $text-size-md;
    font-weight: 700
}
.form {
    width: 100%;

    .input-container {
        margin-bottom: 20px;

        &.error {
            .input {
                border-bottom-color: $red;
            }

            .hint {
                color: #999;
                font: 600 12px $os;
            }

        }

        textarea {
            color: red;
        }

        .input {
            width: 100%;
            height: 44px;
            border-radius: 3px;
            box-sizing: border-box;
            border: 0;
            padding: 0px 10px;
            border: 1px solid #ddd;
            color: #333;
            font-size: 12px;
            font-weight: 400;

            &[readonly] {
                background: #f4f4f4;
                cursor: not-allowed;
            }

            &[type="file"] {
                border: 0;
                padding: 0;
            }
        }

        .hint {
            display: block;
            margin-top: 5px;
            margin-left: 2px;
            font-size: 11.5px;
            color: #aaa;
            font-family: $os;
        }

        label {
            display: block;
            margin-bottom: 5px;
            text-transform: capitalize;
            color: #777;
            font: 600 13px $os;
        }
    }
}

.btn {
    display: inline-block;
    border-radius: 3px;
    border: 0;
    cursor: pointer;

    i {
        margin-right: 5px;
    }

    &.black {
        color: #fff;
        background: #000;
    }

    &.blue {
        color: white;
        background: $blue;
    }

    &.grey {
        background: #eee;
        color: #333;
    }

    &.block {
        width: 100%;
    }

    &.line {
        background: transparent;
        color: #555;
        border: 1px solid #bbb;
    }

    &.lg {
        padding: 17px 34px;
        font-size: 14px;
        font-weight: 600;
    }

    &.md {
        padding: 13px 35px;
        font-size: 11px;
        font: 700 13px $os;

        &.narrow {
            padding: 13px 25px;
        }
    }
}

.hide {
    display: none;
}



.mb-30 {
    margin-bottom: 30px $i;
}
.mt-32 {
    margin-top: 32px;
}
.mr-10 {
    margin-right: 10px;
}
.mt-16 {
    margin-top: 16px;
}
.mt--16 {
    margin-top: -16px;
}
.notif {
    margin-bottom: 30px;
    padding: 15px 20px;
    border-radius: 3px;
    color: white;
    font-size: 14px;

    &.success {
        background: $green;
    }

    &.error {
        background: $red;
    }

    b {
        text-transform: capitalize;
    }
}

.text-center {
    text-align: center;
}